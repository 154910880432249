import axios from 'axios'
import GlobalVariable from './GlobalVariable.js'
import store from '../store'
const API_URL = GlobalVariable.baseUrl
const token = store.state.token != null ? store.state.token : localStorage.getItem('jwt')
export class APIService {
    getClientGroup() {
        const url = `${GlobalVariable.authUrl}/client_group`
        return axios.get(url)
    }
    getPhpInfo() {
        const url = `${GlobalVariable.shipUrl}/web-logs`
        return axios.get(url, { headers: { jwt: token } })
    }
    createClient(clientJson) {
        const url = `${GlobalVariable.authUrl}/v1/client`
        return axios.post(url, clientJson, { headers: { 'Content-Type': 'multipart/form-data' } })
    }
    checkTheme() {
        const url = `${GlobalVariable.authUrl}/theme`
        return axios.get(url, { headers: { jwt: token } })
    }
    msLogin() {
        const url = `${GlobalVariable.authUrl}/ms/connect`
        return axios.get(url, { headers: { jwt: token } })
    }
    checkAuth() {
        const url = `${GlobalVariable.authUrl}/v1/user/auth_check`
        return axios.get(url, { headers: { jwt: token } })
    }
    createAdminClient(clientJson) {
        const url = `${GlobalVariable.authUrl}/v1/user/create_client`
        return axios.post(url, clientJson, { headers: { jwt: token } })
    }
    clientUsers() {
        const url = `${GlobalVariable.authUrl}/v1/user`
        return axios.get(url, { headers: { jwt: token } })
    }
    addclientUser(externalJson) {
        const url = `${GlobalVariable.authUrl}/v1/user/add`
        return axios.post(url, externalJson, { headers: { jwt: token } })
    }
    removeUser(externalJson) {
        const url = `${GlobalVariable.authUrl}/v1/user/delete`
        return axios.post(url, externalJson, { headers: { jwt: token } })
    }
    addUsers(userJson) {
        const url = `${GlobalVariable.authUrl}/v1/user/add/users`
        return axios.post(url, userJson, { headers: { jwt: token } })
    }
    getUserFormInfo() {
        const url = `${GlobalVariable.authUrl}/v1/user/user_form_info`
        return axios.get(url, { headers: { jwt: token } })
    }
    saveUserFormInfo(userJson) {
        const url = `${GlobalVariable.authUrl}/v1/user/user_info`
        return axios.post(url, userJson, { headers: { jwt: token } })
    }
    searchClient(userJson) {
        const url = `${GlobalVariable.authUrl}/v1/user/client/search`
        return axios.post(url, userJson, { headers: { jwt: token } })
    }
    updateUserClient(userJson) {
        const url = `${GlobalVariable.authUrl}/v1/user/client/user/update`
        return axios.post(url, userJson, { headers: { jwt: token } })
    }
    userLogout() {
        const url = `${GlobalVariable.authUrl}/v1/user/logout`
        return axios.get(url, { headers: { jwt: token } })
    }
    getClientNotifications() {
        const url = `${GlobalVariable.authUrl}/v1/user/notifications`
        return axios.get(url, { headers: { jwt: token } })
    }
    readClientNotifications(id) {
        const url = `${GlobalVariable.authUrl}/v1/user/notification/${id}`
        return axios.get(url, { headers: { jwt: token } })
    }
    verifyEmail(verifyJson) {
        const url = `${GlobalVariable.authUrl}/v1/client/email/verify`
        return axios.post(url, verifyJson)
    }
    externalVerifyEmail(verifyJson) {
        const url = `${GlobalVariable.authUrl}/v1/client/external/email/verify`
        return axios.post(url, verifyJson)
    }
    resendEmail(clientId) {
        const url = `${GlobalVariable.authUrl}/v1/client/email/resend/${clientId}`
        return axios.get(url)
    }
    checkVatId(vatJson) {
        const url = `${GlobalVariable.authUrl}/v1/client/check/vat`
        return axios.post(url, vatJson)
    }
    sendEmailOtp(otpJson) {
        const url = `${GlobalVariable.authUrl}/v1/client/forgot_password`
        return axios.post(url, otpJson)
    }
    verifyOtp(otpJson) {
        const url = `${GlobalVariable.authUrl}/v1/client/verify_otp`
        return axios.post(url, otpJson)
    }
    updatePassword(otpJson) {
        const url = `${GlobalVariable.authUrl}/v1/client/update_password`
        return axios.post(url, otpJson)
    }
    userLogin(userJson) {
        const url = `${GlobalVariable.authUrl}/v1/client/login`
        return axios.post(url, userJson)
    }
    getUserIp() {
        const url = `https://api.country.is`
        return axios.get(url)
    }
    getAuthHealth(selectedUrl) {
        console.log()
        const url = `${selectedUrl}/health?fresh`
        return axios.get(url)
    }

    //PIM
    getArticles(articleJson, page) {
        const url = `${GlobalVariable.pimUrl}/v1/articles/?page=${page}`
        return axios.post(url, articleJson, { headers: { jwt: token } })
    }
    getArticleFeatures(articlefeaturesJson, page) {
        const url = `${GlobalVariable.pimUrl}/v1/articlefeatures/?page=${page}`
        return axios.post(url, articlefeaturesJson, { headers: { jwt: token } })
    }
    getProjectFeatures() {
        const url = `${GlobalVariable.pimUrl}/v1/project/articlefeatures`
        return axios.get(url, { headers: { jwt: token } })
    }
    // Collies
    getCollies(articleId) {
        const url = `${GlobalVariable.pimUrl}/v1/collies/${articleId}`
        return axios.get(url, { headers: { jwt: token } })
    }
    deleteCollies(articleId) {
        const url = `${GlobalVariable.pimUrl}/v1/collies/delete/${articleId}`
        return axios.get(url, { headers: { jwt: token } })
    }
    insertCollies(collieJson) {
        const url = `${GlobalVariable.pimUrl}/v1/collies/save`
        return axios.post(url, collieJson, { headers: { jwt: token } })
    }
    deleteArticles(articleJson) {
        const url = `${GlobalVariable.pimUrl}/v1/articles/delete`
        return axios.post(url, articleJson, { headers: { jwt: token } })
    }
    getProducers(producerJson, page) {
        const url = `${GlobalVariable.pimUrl}/v1/article/producers/?page=${page}`
        return axios.post(url, producerJson, { headers: { jwt: token } })
    }
    getStocks() {
        const url = `${GlobalVariable.pimUrl}/v1/stocks`
        return axios.get(url, { headers: { jwt: token } })
    }
    getStockArticles(stockJson, page) {
        const url = `${GlobalVariable.pimUrl}/v1/stocks/article/?page=${page}`
        return axios.post(url, stockJson, { headers: { jwt: token } })
    }
    getStockDetails(stockJson) {
        const url = `${GlobalVariable.pimUrl}/v1/stocks/details`
        return axios.post(url, stockJson, { headers: { jwt: token } })
    }
    addStockArticles(stockJson) {
        const url = `${GlobalVariable.pimUrl}/v1/stock/article/add`
        return axios.post(url, stockJson, { headers: { jwt: token } })
    }
    getArticlesStock(stockJson) {
        const url = `${GlobalVariable.pimUrl}/v1/article/stocks`
        return axios.post(url, stockJson, { headers: { jwt: token } })
    }
    getArticleSuppliers(articlesupplierJson, page) {
        const url = `${GlobalVariable.pimUrl}/v1/article/suppliers/?page=${page}`
        return axios.post(url, articlesupplierJson, { headers: { jwt: token } })
    }
    getArticle(id) {
        const url = `${GlobalVariable.pimUrl}/v1/article/${id}`
        return axios.get(url, { headers: { jwt: token } })
    }
    makeBundleArticle(id) {
        const url = `${GlobalVariable.pimUrl}/v1/article/bundle/status/${id}`
        return axios.get(url, { headers: { jwt: token } })
    }
    getBundleArticles(id) {
        const url = `${GlobalVariable.pimUrl}/v1/article/bundle/${id}`
        return axios.get(url, { headers: { jwt: token } })
    }
    addMakeBundleArticle(id, articleJson) {
        const url = `${GlobalVariable.pimUrl}/v1/article/bundle/add/${id}`
        return axios.post(url, articleJson, { headers: { jwt: token } })
    }
    deleteArticleFromBundle(articleJson, id) {
        const url = `${GlobalVariable.pimUrl}/v1/article/bundle/delete/${id}`
        return axios.post(url, articleJson, { headers: { jwt: token } })
    }
    getArticleTemplates() {
        const url = `${GlobalVariable.pimUrl}/v1/client/templates`
        return axios.get(url, { headers: { jwt: token } })
    }
    getArticleTemplateDetails(id) {
        const url = `${GlobalVariable.pimUrl}/v1/template/${id}`
        return axios.get(url, { headers: { jwt: token } })
    }
    getProjectTemplateDetails(id) {
        const url = `${GlobalVariable.pimUrl}/v1/project/template/${id}`
        return axios.get(url, { headers: { jwt: token } })
    }
    mapProjectTemplateDetails(templateJson) {
        const url = `${GlobalVariable.pimUrl}/v1/article/template/map`
        return axios.post(url, templateJson, { headers: { jwt: token } })
    }
    newArticleTemplate(templateJson) {
        const url = `${GlobalVariable.pimUrl}/v1/template/new`
        return axios.post(url, templateJson, { headers: { jwt: token } })
    }
    editArticleTemplate(templateJson) {
        const url = `${GlobalVariable.pimUrl}/v1/template/edit`
        return axios.post(url, templateJson, { headers: { jwt: token } })
    }
    getArticleProjectsVendors() {
        const url = `${GlobalVariable.pimUrl}/v1/articleprojects/vendors`
        return axios.get(url, { headers: { jwt: token } })
    }
    getArticleProjects() {
        const url = `${GlobalVariable.pimUrl}/v1/articleprojects`
        return axios.get(url, { headers: { jwt: token } })
    }
    saveProjectsDeliveryOption(deliveryJson) {
        const url = `${GlobalVariable.pimUrl}/v1/articleprojects/delivery_option`
        return axios.post(url, deliveryJson, { headers: { jwt: token } })
    }
    getArticleProjectDetails(id) {
        const url = `${GlobalVariable.pimUrl}/v1/articleprojects/${id}`
        return axios.get(url, { headers: { jwt: token } })
    }
    createArticleProject(articleJson) {
        const url = `${GlobalVariable.pimUrl}/v1/articleproject/create`
        return axios.post(url, articleJson, { headers: { jwt: token } })
    }
    updateArticleProject(articleJson) {
        const url = `${GlobalVariable.pimUrl}/v1/articleproject/update`
        return axios.post(url, articleJson, { headers: { jwt: token } })
    }
    addArticleProjectMapping(mappingJson) {
        const url = `${GlobalVariable.pimUrl}/v1/articleproject/map/add`
        return axios.post(url, mappingJson, { headers: { jwt: token } })
    }
    updateArticleProjectMapping(mappingJson) {
        const url = `${GlobalVariable.pimUrl}/v1/articleproject/map/update`
        return axios.post(url, mappingJson, { headers: { jwt: token } })
    }
    getArticleProjectMapping(mappingJson) {
        const url = `${GlobalVariable.pimUrl}/v1/articleprojects/map/list`
        return axios.post(url, mappingJson, { headers: { jwt: token } })
    }
    getArticleProjectLogisticMapping(mappingJson) {
        const url = `${GlobalVariable.pimUrl}/v1/articleprojects/map/logistic/list`
        return axios.post(url, mappingJson, { headers: { jwt: token } })
    }
    deleteArticleProjectMapping(mappingJson) {
        const url = `${GlobalVariable.pimUrl}/v1/articleproject/map/delete`
        return axios.post(url, mappingJson, { headers: { jwt: token } })
    }
    getApiData(vendorId) {
        const url = `${GlobalVariable.pimUrl}/v1/articleprojects/vendor/${vendorId}`
        return axios.get(url, { headers: { jwt: token } })
    }
    getCategories(categorieJson, page) {
        const url = `${GlobalVariable.pimUrl}/v1/categories/?page=${page}`
        return axios.post(url, categorieJson, { headers: { jwt: token } })
    }
    exportMapData(mapJson) {
        const url = `${GlobalVariable.pimUrl}/v1/articleproject/map/export`
        return axios.post(url, mapJson, { headers: { jwt: token } })
    }
    getMappingHeaders(mapJson) {
        const url = `${GlobalVariable.pimUrl}/v1/articleprojects/map/headers`
        return axios.post(url, mapJson, { headers: { jwt: token } })
    }        //ORM
    getOrders(orderJson, page) {
        const url = `${GlobalVariable.ormUrl}/v1/orders/?page=${page}`
        return axios.post(url, orderJson, { headers: { jwt: token } })
    }
    getOrder(id) {
        const url = `${GlobalVariable.ormUrl}/v1/order/${id}`
        return axios.get(url, { headers: { jwt: token } })
    }
    getClientChannels() {
        const url = `${GlobalVariable.ormUrl}/v1/channels/client`
        return axios.get(url, { headers: { jwt: token } })
    }
    getsupplierBasicDetails() {
        const url = `${GlobalVariable.ormUrl}/supplier/basic_details`
        return axios.get(url, { headers: { jwt: token } })
    }
    addSupplier(supplierJson) {
        const url = `${GlobalVariable.ormUrl}/v1/supplier`;
        return axios.post(url, supplierJson, { headers: { jwt: token } });
    }
    addSupplierOptions(supplierJson) {
        const url = `${GlobalVariable.ormUrl}/v1/supplier/options`;
        return axios.post(url, supplierJson, { headers: { jwt: token } });

    }
    updateAddress(addressJson) {
        const url = `${GlobalVariable.ormUrl}/v1/address/update`
        return axios.post(url, addressJson, { headers: { jwt: token } })
    }

    addJob(jobJson) {
        const url = `${GlobalVariable.jobberUrl}/v1/job/add`
        return axios.post(url, jobJson, { headers: { jwt: token } })
    }
    getWarehouses(warehouseJson, page) {
        const url = `${GlobalVariable.shipUrl}/v1/warehouses/?page=${page}`
        return axios.post(url, warehouseJson, { headers: { jwt: token } })
    }
    getOutboundDetails(oubtoundJson) {
        const url = `${GlobalVariable.interfacesUrl}/v1/merhcant/outbound`
        return axios.post(url, oubtoundJson, { headers: { jwt: token } })
    }
    cancelOutbound(oubtoundJson) {
        const url = `${GlobalVariable.interfacesUrl}/v1/ffn/merchant/outbound/cancel`
        return axios.post(url, oubtoundJson, { headers: { jwt: token } })
    }
    lockOutbound(oubtoundJson) {
        const url = `${GlobalVariable.interfacesUrl}/v1/ffn/merchant/outbound/lock`
        return axios.post(url, oubtoundJson, { headers: { jwt: token } })
    }
    reactivateOutbound(oubtoundJson) {
        const url = `${GlobalVariable.interfacesUrl}/v1/ffn/merchant/outbound/reactivate`
        return axios.post(url, oubtoundJson, { headers: { jwt: token } })
    }
    updateOutboundShippingDetails(oubtoundJson) {
        const url = `${GlobalVariable.shipUrl}/v1/jtlffn/shipping/sync_backup`
        return axios.post(url, oubtoundJson, { headers: { jwt: token } })
    }

    changeTrackable(trackableJson) {
        const url = `${GlobalVariable.shipUrl}/v1/shipping/trackable`
        return axios.post(url, trackableJson, { headers: { jwt: token } })
    }

    getBatches(jobJson, page) {
        const url = `${GlobalVariable.jobberUrl}/v1/jobber/info?page=${page}`
        return axios.post(url, jobJson, { headers: { jwt: token } })
    }
    deleteJobBatches(jobJson) {
        const url = `${GlobalVariable.jobberUrl}/v1/batch/delete`
        return axios.post(url, jobJson, { headers: { jwt: token } })
    }
    getJobProgress(batchIds) {
        const url = `${GlobalVariable.jobberUrl}/v1/batch/progres`
        return axios.post(url, batchIds, { headers: { jwt: token } })
    }

    getContacts(contactJson, page) {
        const url = `${GlobalVariable.ormUrl}/v1/contacts/?page=${page}`
        return axios.post(url, contactJson, { headers: { jwt: token } })
    }
    updateAddress(addressJson) {
        const url = `${GlobalVariable.ormUrl}/v1/address/update`
        return axios.post(url, addressJson, { headers: { jwt: token } })
    }

    // Shop
    getClientShops(shopJson) {
        const url = `${GlobalVariable.ormUrl}/v1/shops`
        return axios.post(url, shopJson, { headers: { jwt: token } })
    }
    //Purchase
    getSupplierorders(supplierorderJson, page) {
        const url = `${GlobalVariable.ormUrl}/v1/supplierorders/?page=${page}`
        return axios.post(url, supplierorderJson, { headers: { jwt: token } })
    }

    getSuppliers(supplierJson, page) {
        const url = `${GlobalVariable.ormUrl}/v1/suppliers/?page=${page}`
        return axios.post(url, supplierJson, { headers: { jwt: token } })
    }
    addChannelSKU(MMIJson) {
        const url = `${GlobalVariable.pimUrl}/v1/article/mmi/add`
        return axios.post(url, MMIJson, { headers: { jwt: token } })
    }
    getChannelSKU(MMIJson) {
        const url = `${GlobalVariable.pimUrl}/v1/article/mmi`
        return axios.post(url, MMIJson, { headers: { jwt: token } })
    }

    getSupplier(id) {
        const url = `${GlobalVariable.ormUrl}/v1/supplier/${id}`
        return axios.get(url, { headers: { jwt: token } })
    }

    postSupplier(supplierJson) {
        const url = `${GlobalVariable.ormUrl}/v1/supplier/`
        return axios.post(url, supplierJson, { headers: { jwt: token } })
    }

    getSupplierorder(id) {
        const url = `${GlobalVariable.ormUrl}/v1/supplierorder/${id}`
        return axios.get(url, { headers: { jwt: token } })
    }

    //SHIP
    getDeliveryNotes(deliverynoteJson, page) {
        const url = `${GlobalVariable.shipUrl}/v1/deliverynotes/?page=${page}`
        return axios.post(url, deliverynoteJson, { headers: { jwt: token } })
    }
    getShippingarts(shippingartsJson, page) {
        const url = `${GlobalVariable.shipUrl}/v1/shippingarts/?page=${page}`
        return axios.post(url, shippingartsJson, { headers: { jwt: token } })
    }
    getShipping(id) {
        const url = `${GlobalVariable.shipUrl}/v1/shipping/${id}`
        return axios.get(url, { headers: { jwt: token } })
    }
    getSupplierOption(contactId) {
        const url = `${GlobalVariable.shipUrl}/v1/supplier_option/${contactId}`
        return axios.get(url, { headers: { jwt: token } })
    }
    getShippingart(id) {
        const url = `${GlobalVariable.shipUrl}/v1/shippingart/${id}`
        return axios.get(url, { headers: { jwt: token } })
    }
    createShippment(shipmentJson) {
        const url = `${GlobalVariable.shipUrl}/v1/shipment/create`
        return axios.post(url, shipmentJson, { headers: { jwt: token } })
    }
    getColliesFileData(shipmentJson) {
        const url = `${GlobalVariable.shipUrl}/v1/shipping/collies`
        return axios.post(url, shipmentJson, { headers: { jwt: token } })
    }
    addCollieMapping(shipmentJson) {
        const url = `${GlobalVariable.shipUrl}/v1/shipping/collie/mapping/add`
        return axios.post(url, shipmentJson, { headers: { jwt: token } })
    }
    checkCollieMapping(id) {
        const url = `${GlobalVariable.shipUrl}/v1/shipping/collie/mapping/check/${id}`
        return axios.get(url, { headers: { jwt: token } })
    }
    saveCollie(mappingJson) {
        const url = `${GlobalVariable.shipUrl}/v1/shipping/collie`
        return axios.post(url, mappingJson, { headers: { jwt: token } })
    }
    getShippers() {
        const url = `${GlobalVariable.shipUrl}/v1/client/shippers`
        return axios.get(url, { headers: { jwt: token } })
    }
    getShipmentShippers() {
        const url = `${GlobalVariable.shipUrl}/v1/shipment/shippers`
        return axios.get(url, { headers: { jwt: token } })
    }
    getShipmentShippingArts(shipmentJson) {
        const url = `${GlobalVariable.shipUrl}/v1/shipment/shippers_art`
        return axios.post(url, shipmentJson, { headers: { jwt: token } })
    }
    getAllShippers(shippingArtId) {
        const url = `${GlobalVariable.shipUrl}/v1/all/shippers/${shippingArtId}`
        return axios.get(url, { headers: { jwt: token } })
    }
    saveShipperShippingMap(shippingJson) {
        const url = `${GlobalVariable.shipUrl}/v1/shipper_shipping_art/map`
        return axios.post(url, shippingJson, { headers: { jwt: token } })
    }
    saveInterfaceShippingMap(shippingJson) {
        const url = `${GlobalVariable.shipUrl}/v1/shipper_shipping_art/interface/map`
        return axios.post(url, shippingJson, { headers: { jwt: token } })
    }
    calculateShippingCost() {
        const url = `${GlobalVariable.shipUrl}/v1/shipping/costs/calculate`
        return axios.get(url, { headers: { jwt: token } })
    }
    saveShippingLabel(labelJson) {
        const url = `${GlobalVariable.shipUrl}/v1/shipment/savelabel`
        return axios.post(url, labelJson, { headers: { jwt: token } })
    }
    saveBulkShippingLabel(labelJson) {
        const url = `${GlobalVariable.shipUrl}/v1/shipment/savelabel/bulk`
        return axios.post(url, labelJson, { headers: { jwt: token }, responseType: 'arraybuffer' })
    }
    getShippingList(shippingJson, page) {
        const url = `${GlobalVariable.shipUrl}/v1/fetch/shipping/?page=${page}`;
        return axios.post(url, shippingJson, { headers: { jwt: token } });
    }

    updateShipping(shippingJson) {
        const url = `${GlobalVariable.interfacesUrl}/v1/shopify/fulfillments/update`;
        return axios.post(url, shippingJson, { headers: { jwt: token } });
    }
    getShippingMethods() {
        const url = `${GlobalVariable.interfacesUrl}/v1/ffn/merchant/outbound/shipping_methods`;
        return axios.get(url, { headers: { jwt: token } });
    }

    //DROPY
    getMyinterfaces() {
        const url = `${GlobalVariable.interfacesUrl}/v1/myinterfaces`
        return axios.get(url, { headers: { jwt: token } })
    }
    getMySupplierInterfaces() {
        const url = `${GlobalVariable.interfacesUrl}/v1/myinterfaces/supplier`
        return axios.get(url, { headers: { jwt: token } })
    }
    getMyShipperInterfaces() {
        const url = `${GlobalVariable.interfacesUrl}/v1/myinterfaces/shipper`
        return axios.get(url, { headers: { jwt: token } })
    }
    saveSupplierInterface(interfaceJson) {
        const url = `${GlobalVariable.interfacesUrl}/v1/myinterface/contact`
        return axios.post(url, interfaceJson, { headers: { jwt: token } })
    }
    checkSupplierInterface(interfaceJson) {
        const url = `${GlobalVariable.interfacesUrl}/v1/myinterface/contact/check`
        return axios.post(url, interfaceJson, { headers: { jwt: token } })
    }
    createShippingOrder(interfaceJson) {
        const url = `${GlobalVariable.interfacesUrl}/v1/shipping/create/order`
        return axios.post(url, interfaceJson, { headers: { jwt: token } })
    }
    updateOutbound(interfaceJson) {
        const url = `${GlobalVariable.interfacesUrl}/v1/ffn/merchant/outbound/update`
        return axios.post(url, interfaceJson, { headers: { jwt: token } })
    }
    autoUpdateOutbound(interfaceJson) {
        const url = `${GlobalVariable.interfacesUrl}/v1/ffn/merchant/outbound/auto_update`
        return axios.post(url, interfaceJson, { headers: { jwt: token } })
    }
    resetPosition(interfaceJson) {
        const url = `${GlobalVariable.interfacesUrl}/v1/ffn/merchant/position/reset`
        return axios.post(url, interfaceJson, { headers: { jwt: token } })
    }
    resetShippingAddress(interfaceJson) {
        const url = `${GlobalVariable.interfacesUrl}/v1/ffn/merchant/outbound/address/reset`
        return axios.post(url, interfaceJson, { headers: { jwt: token } })
    }
    createFullfilment(shipmentJson) {
        const url = `${GlobalVariable.interfacesUrl}/v1/ffn/merchant/outbound/store`
        return axios.post(url, shipmentJson, { headers: { jwt: token } })
    }
    trackShippingOrder(interfaceJson) {
        const url = `${GlobalVariable.interfacesUrl}/v1/shipping/track/order`
        return axios.post(url, interfaceJson, { headers: { jwt: token } })
    }
    getInterfaces() {
        const url = `${GlobalVariable.interfacesUrl}/v1/interfaces`
        return axios.get(url)
    }
    getInterfaceStatistic(id) {
        const url = `${GlobalVariable.interfacesUrl}/v1/interface/statistic/${id}`
        return axios.get(url, { headers: { jwt: token } })
    }
    getMyinterface(id) {
        const url = `${GlobalVariable.interfacesUrl}/v1/myinterface/${id}`
        return axios.get(url, { headers: { jwt: token } })
    }
    updateExternalNo(externalJson) {
        const url = `${GlobalVariable.interfacesUrl}/v1/myinterface/save_shipping`
        return axios.post(url, externalJson, { headers: { jwt: token } })
    }
    bookNewInterface(id, interfaceJson) {
        const url = `${GlobalVariable.interfacesUrl}/v1/myinterface/new/${id}`
        return axios.post(url, interfaceJson, { headers: { jwt: token } })
    }
    updateMyinterface(myinterfaceJson) {
        const url = `${GlobalVariable.interfacesUrl}/v1/myinterface/`
        return axios.post(url, myinterfaceJson, { headers: { jwt: token } })
    }
    testIntefaceConnection(interfaceId) {
        const url = `${GlobalVariable.interfacesUrl}/v1/myinterface/test/${interfaceId}`
        return axios.get(url, { headers: { jwt: token } })
    }
    saveIntefaceOptions(interfaceJson, interfaceId) {
        const url = `${GlobalVariable.interfacesUrl}/v1/myinterface/option/${interfaceId}`
        return axios.post(url, interfaceJson, { headers: { jwt: token } })
    }
    saveIntefaceStatus(interfaceJson) {
        const url = `${GlobalVariable.interfacesUrl}/v1/myinterface/status`
        return axios.post(url, interfaceJson, { headers: { jwt: token } })
    }

    organizeOrder(interfaceJson) {
        const url = `${GlobalVariable.interfacesUrl}/v1/external/order/reorganize`
        return axios.post(url, interfaceJson, { headers: { jwt: token } })
    }

    getModules() {
        const url = `${API_URL}/v1/modules`
        return axios.get(url)
    }

    //Settings
    getUsers() {
        const url = `${API_URL}/v1/users`
        return axios.get(url)
    }

    // DMS
    uploadPublicImage(articleJson) {
        const url = `${GlobalVariable.dmsUrl}/v1/public/images/store`
        return axios.post(url, articleJson, { headers: { jwt: token }, 'Content-Type': 'multipart/form-data' })
    }
    getPublicImages(articleJson) {
        const url = `${GlobalVariable.dmsUrl}/v1/public/images`
        return axios.post(url, articleJson, { headers: { jwt: token }, 'Content-Type': 'multipart/form-data' })
    }
    deletePublicImages(articleJson) {
        const url = `${GlobalVariable.dmsUrl}/v1/public/images/delete`
        return axios.post(url, articleJson, { headers: { jwt: token } })
    }
    uploadArticleFile(articleJson) {
        const url = `${GlobalVariable.dmsUrl}/v1/articleprojects/upload`
        return axios.post(url, articleJson, { headers: { jwt: token }, 'Content-Type': 'multipart/form-data' })
    }
    addArticleImages(mappingJson) {
        const url = `${GlobalVariable.dmsUrl}/v1/articleproject/picture/add`
        return axios.post(url, mappingJson, { headers: { jwt: token, 'Content-Type': 'multipart/form-data' } })
    }
    addUserImages(userImage) {
        const url = `${GlobalVariable.dmsUrl}/v1/user/image`
        return axios.post(url, userImage, { headers: { jwt: token, 'Content-Type': 'multipart/form-data' } })
    }
    viewArticleImages(mappingJson) {
        const url = `${GlobalVariable.dmsUrl}/v1/articleproject/pictures`
        return axios.post(url, mappingJson, { headers: { jwt: token } })
    }
    viewPrivateImage(dir, projectId, fileName) {
        const url = `${GlobalVariable.dmsWebUrl}/${dir}/projects/${projectId}/images/${fileName}`
        return axios.get(url, { headers: { jwt: token } })
    }
    viewPrivateLabel(dir, fileName) {
        const url = `${GlobalVariable.dmsWebUrl}/${dir}/outbox/label/${fileName}`
        return axios.get(url, { headers: { jwt: token } })
    }
    renameArticleImages(mappingJson) {
        const url = `${GlobalVariable.dmsUrl}/v1/articleproject/pictures/rename`
        return axios.post(url, mappingJson, { headers: { jwt: token } })
    }
    deleteArticleImage(imageJson) {
        const url = `${GlobalVariable.dmsUrl}/v1/articleproject/pictures/delete`
        return axios.post(url, imageJson, { headers: { jwt: token } })
    }
    getThumbnail(imageJson) {
        const url = `${GlobalVariable.dmsUrl}/v1/articleproject/thumbnail`
        return axios.post(url, imageJson, { headers: { jwt: token } })
    }
    importArticleFileData(articleJson) {
        const url = `${GlobalVariable.dmsUrl}/v1/articleproject/import`
        return axios.post(url, articleJson, { headers: { jwt: token } })
    }

    getArticleProjectMappingHeaders(mappingJson) {
        const url = `${GlobalVariable.dmsUrl}/v1/articleproject/import/headers`
        return axios.post(url, mappingJson, { headers: { jwt: token } })
    }

    //TT

    updateTracking(trackingJson) {
        const url = `${GlobalVariable.ttUrl}/v1/tt/tracking`
        return axios.post(url, trackingJson, { headers: { jwt: token } })
    }

    getTrackings(trackingJson, page) {
        const url = `${GlobalVariable.ttUrl}/v1/tt/?page=${page}`
        return axios.post(url, trackingJson, { headers: { jwt: token } })
    }
    getShippingTrackings(shippingId) {
        const url = `${GlobalVariable.ttUrl}/v1/shipping/${shippingId}`
        return axios.get(url, { headers: { jwt: token } })
    }

    // Reports
    getDashboardReport(reportJson) {
        const url = `${GlobalVariable.reportUrl}/v1/dashboard`
        return axios.post(url, reportJson, { headers: { jwt: token } })
    }

    getDashboardSeriesData(seriesData) {
        const url = `${GlobalVariable.reportUrl}/v1/series_data`;
        return axios.post(url, seriesData, { headers: { jwt: token } });
    }

    getDashBoardMonthData() {
        const url = `${GlobalVariable.reportUrl}/v1/month_orders`;
        return axios.post(url, { headers: { jwt: token } });
    }

    // Contract
    getContract(contractJson, page) {
        const url = `${GlobalVariable.authUrl}/v1/contracts/?page=${page}`;
        return axios.post(url, contractJson, { headers: { jwt: token } });
    }

    storeContract(contractJson) {
        const url = `${GlobalVariable.authUrl}/v1/contracts/store`;
        return axios.post(url, contractJson, { headers: { jwt: token } });
    }

    editContract(contractJson) {
        const url = `${GlobalVariable.authUrl}/v1/contracts/edit`;
        return axios.post(url, contractJson, { headers: { jwt: token } });
    }

    deleteContract(id) {
        const url = `${GlobalVariable.authUrl}/v1/contracts/delete/${id}`;
        return axios.get(url, { headers: { jwt: token } });
    }

    getContractDetails(id) {
        const url = `${GlobalVariable.authUrl}/v1/contracts/${id}`;
        return axios.get(url, { headers: { jwt: token } });
    }

    storeContractDocument(docJson) {
        const url = `${GlobalVariable.authUrl}/v1/contract_documents/store`;
        return axios.post(url, docJson, { headers: { jwt: token } });
    }

    editContractDocument(docJson) {
        const url = `${GlobalVariable.authUrl}/v1/contract_documents/edit`;
        return axios.post(url, docJson, { headers: { jwt: token } });
    }

    deleteContractDocument(id) {
        const url = `${GlobalVariable.authUrl}/v1/contract_documents/delete/${id}`;
        return axios.get(url, { headers: { jwt: token } });
    }

    getContractDocument(contractId) {
        const url = `${GlobalVariable.authUrl}/v1/contract_documents/${contractId}`;
        return axios.get(url, { headers: { jwt: token } });
    }

    getContractDocumentDetails(id) {
        const url = `${GlobalVariable.authUrl}/v1/contract_documents/details/${id}`;
        return axios.get(url, { headers: { jwt: token } });
    }

    getClientAttributes() {
        const url = `${GlobalVariable.authUrl}/v1/client/attribute`;
        return axios.get(url, { headers: { jwt: token } });
    }

    fetchLanguages() {
        const url = `${GlobalVariable.authUrl}/v1/languages`;
        return axios.get(url, { headers: { jwt: token } });
    }

    fetchClientContracts() {
        const url = `${GlobalVariable.authUrl}/v1/client/fetch/contract`;
        return axios.get(url, { headers: { jwt: token } });
    }

    acceptDocument(docJson) {
        const url = `${GlobalVariable.authUrl}/v1/client/accept/contract`;
        return axios.post(url, docJson, { headers: { jwt: token } });
    }


    fetchCCAccountTypes() {
        const url = `${GlobalVariable.ccUrl}/v1/cc/account/types`;
        return axios.get(url);
    }

    addCCAccount(ccJson) {
        const url = `${GlobalVariable.ccUrl}/v1/add/cc/account`;
        return axios.post(url, ccJson, { headers: { jwt: token } });
    }

    fetchCCAccount() {
        const url = `${GlobalVariable.ccUrl}/v1/fetch/cc/accounts`;
        return axios.get(url, { headers: { jwt: token } });
    }

    deleteCCAccount(id) {
        const url = `${GlobalVariable.ccUrl}/v1/delete/cc/account/${id}`;
        return axios.get(url);
    }

    editCCAccount(ccJson) {
        const url = `${GlobalVariable.ccUrl}/v1/edit/cc/account`;
        return axios.post(url, ccJson, { headers: { jwt: token } });
    }

    updateArticleBufferStock(articleJson) {
        const url = `${GlobalVariable.pimUrl}/v1/update/article/buffer/stock`;
        return axios.post(url, articleJson, { headers: { jwt: token } });
    }

    fetchClientInbox(inboxJson, page) {
        const url = `${GlobalVariable.ccUrl}/v1/fetch/client/inbox/?page=${page}`;
        return axios.post(url, inboxJson, { headers: { jwt: token } });
    }

    updateBulkShipping(shipJson) {
        const url = `${GlobalVariable.interfacesUrl}/v1/shopify/fulfillments/bulk/update`;
        return axios.post(url, shipJson, { headers: { jwt: token } });
    }

    checkShipping(shipJson) {
        const url = `${GlobalVariable.interfacesUrl}/v1/shopify/shipping/check`;
        return axios.post(url, shipJson, { headers: { jwt: token } });
    }

    fetchArticles() {
        const url = `${GlobalVariable.pimUrl}/v1/fetch/articles`;
        return axios.get(url, { headers: { jwt: token } });
    }
    fetchArticlesv2(artJson) {
        const url = `${GlobalVariable.pimUrl}/v1/fetch/articles/v2`;
        return axios.post(url, artJson, { headers: { jwt: token } });
    }

    fetchSuppliers() {
        const url = `${GlobalVariable.ormUrl}/v1/fetch/suppliers`;
        return axios.get(url, { headers: { jwt: token } });
    }

    storeSupplierOrder(soJson) {
        const url = `${GlobalVariable.ormUrl}/v1/supplierorder/store`;
        return axios.post(url, soJson, { headers: { jwt: token } });
    }

    storesupplierArticleRelation(relJson) {
        const url = `${GlobalVariable.pimUrl}/v1/article/suppliers/store`;
        return axios.post(url, relJson, { headers: { jwt: token } });
    }

    createInbound(inboundJson) {
        const url = `${GlobalVariable.ormUrl}/v1/ffn/inbound`;
        return axios.post(url, inboundJson, { headers: { jwt: token } });
    }

    fetchShippers() {
        const url = `${GlobalVariable.shipUrl}/v1/shippers`;
        return axios.get(url);
    }

    updateShipper(shipJson) {
        const url = `${GlobalVariable.shipUrl}/v1/shipper/logo/update`;
        return axios.post(url, shipJson);
    }

    updateInterfaceName(interfaceJson) {
        const url = `${GlobalVariable.interfacesUrl}/v1/myinterface/name/update`;
        return axios.post(url, interfaceJson, { headers: { jwt: token } });
    }

    fetchArticleImages(articleJson) {
        const url = `${GlobalVariable.dmsUrl}/v1/article/images`;
        return axios.post(url, articleJson, { headers: { jwt: token } });
    }

    syncArticleImage(articleJson) {
        const url = `${GlobalVariable.dmsUrl}/v1/shopify/article/image/store`;
        return axios.post(url, articleJson, { headers: { jwt: token } });
    }

    fetchVendorArticle(vendorJson) {
        const url = `${GlobalVariable.pimUrl}/v1/fetch/vendor/article`;
        return axios.post(url, vendorJson, { headers: { jwt: token } });
    }

    fetchSupplierOrderDetails(soJson) {
        const url = `${GlobalVariable.ormUrl}/v1/fetch/supplierorder/details`;
        return axios.post(url, soJson, { headers: { jwt: token } });
    }

    editSupplierOrderDetails(soJson) {
        const url = `${GlobalVariable.ormUrl}/v1/supplierorder/edit`;
        return axios.post(url, soJson, { headers: { jwt: token } });
    }

    addInboundPackages(inboundJson) {
        const url = `${GlobalVariable.shipUrl}/v1/jtlffn/inbound/packages`;
        return axios.post(url, inboundJson, { headers: { jwt: token } });
    }

    getInboundDetails(inboundJson) {
        const url = `${GlobalVariable.interfacesUrl}/v1/merhcant/inbound`;
        return axios.post(url, inboundJson, { headers: { jwt: token } });
    }

    deleteSupplierArticle(id) {
        const url = `${GlobalVariable.pimUrl}/v1/supplierarticle/delete/${id}`
        return axios.get(url, { headers: { jwt: token } });
    }

    searchOrderClient(clientJson) {
        const url = `${GlobalVariable.authUrl}/v1/user/ordnr/client/search`;
        return axios.post(url, clientJson, { headers: { jwt: token } });
    }

    updateShopifyGraphFulfillment(shippingJson) {
        const url = `${GlobalVariable.interfacesUrl}/v1/shopify/graph/fulfillment/update`;
        return axios.post(url, shippingJson, { headers: { jwt: token } });
    }

    fetchClientCompanyDetails() {
        const url = `${GlobalVariable.authUrl}/v1/client/fetch/company/details`;
        return axios.get(url, { headers: { jwt: token } });
    }

    fetchClientCustomers(clientJson) {
        const url = `${GlobalVariable.ormUrl}/v1/search/client/customers`;
        return axios.post(url, clientJson, { headers: { jwt: token } });
    }

    getClientCompanyAttribute() {
        const url = `${GlobalVariable.authUrl}/v1/client/company/attribute`;
        return axios.get(url, { headers: { jwt: token } });
    }

    getAddressAttribute() {
        const url = `${GlobalVariable.ormUrl}/address/attribute`;
        return axios.get(url);
    }

    getArticleAttribute() {
        const url = `${GlobalVariable.pimUrl}/get/article/attribute`;
        return axios.get(url);
    }

    createInvoice(invoiceJson) {
        const url = `${GlobalVariable.ormUrl}/v1/create/invoice`;
        return axios.post(url, invoiceJson, { headers: { jwt: token } });
    }

    storeWarehouse(warehouseJson) {
        const url = `${GlobalVariable.pimUrl}/v1/create/warehouse`;
        return axios.post(url, warehouseJson, { headers: { jwt: token } });
    }

    fetchClientArticles(clientJson) {
        const url = `${GlobalVariable.ormUrl}/v1/search/client/articles`;
        return axios.post(url, clientJson, { headers: { jwt: token } });
    }

    createExternalInvoice(invoiceJson) {
        const url = `${GlobalVariable.ormUrl}/v1/create/external/invoice`;
        return axios.post(url, invoiceJson, { headers: { jwt: token } });
    }

    fetchClientInvoices(invoiceJson, page) {
        const url = `${GlobalVariable.ormUrl}/v1/fetch/client/invoices/?page=${page}`;
        return axios.post(url, invoiceJson, { headers: { jwt: token } });
    }

    storeInvoicePdf(invoiceJson) {
        const url = `${GlobalVariable.dmsUrl}/v1/store/invoice/pdf`;
        return axios.post(url, invoiceJson, { headers: { jwt: token }, 'Content-Type': 'multipart/form-data' })
    }

    storeArticle(articleJson) {
        const url = `${GlobalVariable.pimUrl}/v1/store/article`;
        return axios.post(url, articleJson, { headers: { jwt: token } });
    }

    fetchNumberRanges(numberJson) {
        const url = `${GlobalVariable.authUrl}/v1/fetch/client/number/ranges`
        return axios.post(url, numberJson, { headers: { jwt: token } })
    }

    editNumberRange(numberJson) {
        const url = `${GlobalVariable.authUrl}/v1/edit/number/range`;
        return axios.post(url, numberJson, { headers: { jwt: token } });
    }

    report(reportJson) {
        const url = `${GlobalVariable.reportUrl}/v1/report`;
        return axios.post(url, reportJson, { headers: { jwt: token } });
    }

    storeLanguageRelation(langJson) {
        const url = `${GlobalVariable.authUrl}/v1/store/client/language/relation`;
        return axios.post(url, langJson, { headers: { jwt: token } });
    }

    fetchClientPreferredLanguage() {
        const url = `${GlobalVariable.authUrl}/v1/fetch/client/preferred/languages`;
        return axios.get(url, { headers: { jwt: token } });
    }

    removeLanguageRelation(langJson) {
        const url = `${GlobalVariable.authUrl}/v1/remove/language/relation`;
        return axios.post(url, langJson, { headers: { jwt: token } });
    }

    storeArticleDescription(descJson) {
        const url = `${GlobalVariable.pimUrl}/v1/store/article/description`;
        return axios.post(url, descJson, { headers: { jwt: token } });
    }
    editArticleDescription(descJson) {
        const url = `${GlobalVariable.pimUrl}/v1/edit/article/description`;
        return axios.post(url, descJson, { headers: { jwt: token } });
    }

    updateArticlePrice(priceJson) {
        const url = `${GlobalVariable.pimUrl}/v1/update/article/price`;
        return axios.post(url, priceJson, { headers: { jwt: token } });
    }

    fetchCVPOrders(cvpJson, page) {
        const url = `${GlobalVariable.wmsUrl}/V1/fetch/cvp/orders/?page=${page}`;
        return axios.post(url, cvpJson);
    }

    makeCVPActive(cvpJson) {
        const url = `${GlobalVariable.wmsUrl}/V1/order/reset`;
        return axios.post(url, cvpJson);
    }

    storeArticleImage(imageJson) {
        const url = `${GlobalVariable.dmsUrl}/v1/add/article/image`;
        return axios.post(url, imageJson, { headers: { jwt: token, 'Content-Type': 'multipart/form-data' } });
    }

    fetchCVPOrderArticles(cvpJson) {
        const url = `${GlobalVariable.wmsUrl}/V1/fetch/cvp/order/articles`;
        return axios.post(url, cvpJson);
    }

    fetchCvpOrderSummary() {
        const url = `${GlobalVariable.wmsUrl}/V1/fetch/cvporder/summary`;
        return axios.get(url);
    }

    fetchFFnInterfaceSummary(ffnJson) {
        const url = `${GlobalVariable.reportUrl}/v1/fetch/ffn/interface/summary`;
        return axios.post(url, ffnJson, { headers: { jwt: token } });
    }

    fetchFFnInterfaceList(ffnJson) {
        const url = `${GlobalVariable.reportUrl}/v1/fethc/summary/list`;
        return axios.post(url, ffnJson, { headers: { jwt: token } });
    }

    fetchOutboundReport(reportJson) {
        const url = `${GlobalVariable.reportUrl}/v1/fetch/outbound/report`;
        return axios.post(url, reportJson, { headers: { jwt: token } });
    }

    uploadLabel(labelJson) {
        const url = `${GlobalVariable.interfacesUrl}/v1/ffn/merchant/outbound/label`;
        return axios.post(url, labelJson, { headers: { jwt: token } });
    }

    getDeliveryNotesv2(deliverynoteJson, page) {
        const url = `${GlobalVariable.shipUrl}/v1/deliverynotes_v2/?page=${page}`
        return axios.post(url, deliverynoteJson, { headers: { jwt: token } })
    }

    updatePartialShipping(shippingJson) {
        const url = `${GlobalVariable.interfacesUrl}/v1/shopify/fulfillments/rest/update`;
        return axios.post(url, shippingJson, { headers: { jwt: token } });
    }

    deleteSupplierOrder(soJson) {
        const url = `${GlobalVariable.ormUrl}/v1/supplierorder/delete`
        return axios.post(url, soJson, { headers: { jwt: token } });
    }

    updatePlentyShipping(plentyJSon) {
        const url = `${GlobalVariable.interfacesUrl}/v1/plenty/shipping/packages`;
        return axios.post(url, plentyJSon, { headers: { jwt: token } });
    }

    fetchCVPShipperList() {
        const url = `${GlobalVariable.wmsUrl}/v1/shippers`;
        return axios.get(url);
    }

    storeCVPShippingCredentials(cvpJson) {
        const url = `${GlobalVariable.wmsUrl}/v1/cvp/shipping_credentials`;
        return axios.post(url, cvpJson, { headers: { jwt: token } });
    }

    fetchCVPShippingCredentials() {
        const url = `${GlobalVariable.wmsUrl}/v1/cvp/shipping_credentials`;
        return axios.get(url, { headers: { jwt: token } });
    }

    storeCVPShippingMethods(smJson) {
        const url = `${GlobalVariable.wmsUrl}/v1/cvp/shipping_methods`;
        return axios.post(url, smJson, { headers: { jwt: token } });
    }

    displayCVPShippingMethods(credentialId) {
        const url = `${GlobalVariable.wmsUrl}/v1/cvp/shipping_methods/${credentialId}`
        return axios.get(url, { headers: { jwt: token } });
    }

    storeMerchantOutboundAttachment(attachmentJson) {
        const url = `${GlobalVariable.interfacesUrl}/v1/ffn/merchant/outbound/attachment`;
        return axios.post(url, attachmentJson, { headers: { jwt: token }, 'Content-Type': 'multipart/form-data' })
    }

    fetchWarehouseDetails(stockId) {
        const url = `${GlobalVariable.pimUrl}/v1/stocks/${stockId}`;
        return axios.get(url, { headers: { jwt: token } });
    }

    generateAuthCode(stockId) {
        const url = `${GlobalVariable.authUrl}/v1/stock/${stockId}/auth_code`;
        return axios.get(url, { headers: { jwt: token } });
    }

    fetchStockTypes() {
        const url = `${GlobalVariable.pimUrl}/v1/stock_types`;
        return axios.get(url, { headers: { jwt: token } });
    }

    updateWarehouse(warehouseJson) {
        const url = `${GlobalVariable.pimUrl}/v1/update/warehouse`;
        return axios.post(url, warehouseJson, { headers: { jwt: token } });
    }
}

